import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Icon from '../icon';

const AlertCircle = ({ className, ...other }) => {
  const { iconSvg } = useStaticQuery(graphql`
    {
      iconSvg(icon: "alert-circle")
    }
  `);
  return <Icon className={className} icon={iconSvg} {...other} />;
};

export default AlertCircle;
